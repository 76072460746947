import React, { useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from "../../components/seo";
import PGSideBar from '../../components/pg-side-bar';

import '../../styles/cus.css';

const PatentGuide_FivePatentClasses = () => {
    const seo = {
        metaDesc: 'What Are The Five Patent Classes? - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="What Are The Five Patent Classes? - Inventiv.org" canonical='/patent-guide' seo={seo} />
            <div class="sub-banner main-banner">
                <div class="container">
                    <div class="breadcrumb-area" style={{ top: 30 + '%' }}>
                        <h1 className='fw-special-title'>&nbsp;</h1>
                        <h1 className='title-banner'>What Are The Five Patent Classes?</h1>
                    </div>
                </div>
            </div>

            <section class="mt-3 mb-3 pt-3 pb-3 wow fadeInUp delay-04s">
                <div class="container ">
                    <div className='row'>
                        <div className='col-md-8 col-sm-12'>
                            <figure className='text-center'>
                                <img width="300" height="300"
                                    src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300,h_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png"
                                    data-src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300,h_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png"
                                    class="vc_single_image-img attachment-medium lazyloaded" alt="" decoding="async"
                                    data-srcset="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png 300w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_150/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-150x150.png 150w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_100/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-100x100.png 100w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_512/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1.png 512w"
                                    data-sizes="(max-width: 300px) 100vw, 300px" data-pagespeed-url-hash="2358126750" sizes="(max-width: 300px) 100vw, 300px"
                                    srcset="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png 300w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_150/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-150x150.png 150w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_100/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-100x100.png 100w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_512/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1.png 512w" />
                            </figure>
                            <div class="wpb_wrapper">
                                <h2>What Are The Five Patent Classes?</h2>
                                <h3><b><i>1. Processes and methods</i></b></h3>
                                <p><span style={{ fontWeight: 400 }}>These are steps for doing or making something.&nbsp; They can be steps that
                                    change something physically or even manual steps, like how you type on a keyboard.&nbsp; They cannot,
                                    however, consists of only mental steps.</span></p>
                                <p><span style={{ fontWeight: 400 }}>There are primarily three categories of processes:</span></p>
                                <p><b>1. conventional</b></p>
                                <p><span style={{ fontWeight: 400 }}>This is what you would traditionally consider a process, like a chemical reaction
                                    or steps to physically construct an item.</span></p>
                                <p><b>2. software</b></p>
                                <p><span style={{ fontWeight: 400 }}>To be classified as a process or method, a software program must produce a
                                    tangible, utilitarian result, or affect some piece of hardware or another software.&nbsp; The federal court
                                    in </span><i><span style={{ fontWeight: 400 }}>In re Bilski</span></i><span style={{ fontWeight: 400 }}> stated
                                        software processes must transform an article into a different thing or state, or it must be related to a
                                        specific machine or apparatus.</span></p>
                                <p><b>3. business</b></p>
                                <p><span style={{ fontWeight: 400 }}>This includes methods of conducting business, such as software to calculate
                                    financial records.&nbsp; It is also circumscribed by the decision in </span><i><span
                                        style={{ fontWeight: 400 }}>In re Bilski.</span></i></p>
                                <h3><b><i>2. Machines</i></b></h3>
                                <p><span style={{ fontWeight: 400 }}>Machines are devices or things that interact and, as a result, accomplish
                                    something.&nbsp; It’s related to processes but the focus here is on the actual machine, not the methods that
                                    the machine employees.&nbsp; Machines can include traditional notions of what is a machine, such as a sewing
                                    machine. It also covers software depending on how you write the patent application.</span></p>
                                <h3><b><i>3. articles of manufacture</i></b></h3>
                                <p><span style={{ fontWeight: 400 }}>This includes what results from manufacturing or what’s made by humans.&nbsp;
                                    It’s an invention that accomplishes something without the interaction of moveable parts or objects with
                                    incidental moveable parts.&nbsp; For example, knives, erasers or books are articles of manufacture.&nbsp;
                                    Usually, naturally occurring things do not fit into this category but there are some exceptions, e.g. a
                                    novel, non-obvious use of a natural thing.&nbsp; Also, there may be some overlap between articles of
                                    manufacture, and machines or compositions of matter.</span></p>
                                <h3><b><i>4. compositions of matter</i></b></h3>
                                <p><span style={{ fontWeight: 400 }}>Chemicals or other material combinations that are produced as a result of
                                    chemicals are compositions of matter. This includes glues, plastics and gasoline.&nbsp; Natural items are
                                    not considered compositions of matter but, for example, their derivative chemicals or extracts in purified
                                    form are.&nbsp;&nbsp;</span></p>
                                <p><span style={{ fontWeight: 400 }}>Compositions of matter often overlap with articles of manufacture.&nbsp; However,
                                    compositions are primarily concerned with the chemical building blocks rather than the shape of the
                                    article.</span></p>
                                <h3><b><i>5. improvements</i></b></h3>
                                <p><span style={{ fontWeight: 400 }}>Improvements are novel uses for an existing invention. Under this category, you
                                    may need a license from the original patent owner if the underlying invention is still patent
                                    protected.</span></p>
                                <p>&nbsp;</p>
                            </div>
                            <div className='mt-5'>
                                <p>
                                    <a href='/inventor-resources' className='btn btn-warning rounded-pill px-5'>Download Provisional Patent Assistant Now!</a>
                                </p>
                            </div>
                        </div>

                        <div className='col-md-4 col-sm-12'>
                            <PGSideBar></PGSideBar>
                        </div>
                    </div>

                </div>
            </section>

        </Layout>
    )
};

export default PatentGuide_FivePatentClasses;